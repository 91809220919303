import React from 'react';
import { Card, CardContent, Typography, Box, Button, Grid, IconButton, CircularProgress, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { formatCurrency } from '../../utils/Utils'; // Assuming you have this utility function



function getTimeDifference(dateString) {
    const now = new Date();
    const date = new Date(dateString);
    const diffMs = now - date;
  
    const minutes = Math.floor(diffMs / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (minutes < 60) {
      return `${minutes}m`;
    } else if (hours < 24) {
      return `${hours}h`;
    } else if (days === 1) {
      const remainingHours = hours % 24;
      return `${days}d ${remainingHours}h`;
    } else {
      return `${days}d`;
    }
  }
  
  function renderTimeSinceInquiry(dateString) {
    const timeDifference = getTimeDifference(dateString);
  
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#ADD8E6', color: 'black', borderRadius: '12px', padding: '1px 6px', fontSize: '10px', ml: 'auto',   boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)', // Soft shadow for faded border effect
      }}>
        <Typography variant="caption" sx={{ fontSize: '12px', lineHeight: '16px', fontWeight: 'bold' }}>
          {timeDifference}
        </Typography>
      </Box>
    );
  }
  
  const paleColors = ['#ADD8E6', '#FADADD', '#E0BBE4', '#C3E8AC', '#FFDAB9', '#FFDFD3'];
  
  const generateHash = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };
  
  const getColorForLead = (lead) => {
    const combinedString = `${lead.name}${lead.details.address.split(',')[1]}${lead.details.state}`;
    const hash = generateHash(combinedString);
    const colorIndex = Math.abs(hash) % paleColors.length;
    return paleColors[colorIndex];
  };
  
// Utility function to render success or error alerts
const renderAlert = (message, type, isMobile) => {
  
  if (!message) return null; // Return nothing if there's no message

  const severity = type === 'success' ? 'success' : 'error';
  const title = type === 'success' ? 'Success' : 'Error';

  return (
    <Alert severity={severity} sx={{ mt: 2, width: isMobile ? '100%': '350px' }}>
      <Typography variant="h6">{title}</Typography>
      <pre>{message}</pre>
    </Alert>
  );
};
  
// Reusable component for rendering lead card
function SmallLeadCard({ lead, handleExpandClick }) {
    return (
    <Card
      sx={{
        boxShadow: 2,
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        transition: 'transform 0.3s ease-in-out',
        cursor: 'pointer',
        height: '140px',
        '&:hover': {
          transform: 'scale(1.02)',
        },
        overflow: 'hidden',
      }}
      onClick={() => handleExpandClick(lead._id)}
    >
      <CardContent 
        sx={{ 
          padding: '8px',
          display: 'flex', 
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          '&:last-child': {
            paddingBottom: '8px !important',
          },
        }}
      >  
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
          <Box sx={{
            width: 36,
            height: 36,
            borderRadius: '50%',
            backgroundColor: getColorForLead(lead),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '8px',
          }}>
            <Typography variant="subtitle2" fontWeight="bold" sx={{ color: 'white' }}>
              {lead.name[0]}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="subtitle2" fontWeight="bold" sx={{ lineHeight: '20px', mr: 1 }}>
              {lead.name.split(' ')[0]}
            </Typography>
            <Typography variant="caption">
              {lead.details.address.split(',')[1]} {lead.details.state}
            </Typography>
          </Box>

          {renderTimeSinceInquiry(lead.date)}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mt: 0, ml: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#66BB6A', color: 'white', borderRadius: '12px', padding: '1px 6px', fontSize: '12px', mr: 1 ,boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',}}>
              <CheckCircleIcon sx={{ fontSize: '14px', mr: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
              SMS Verified
            </Box>
            {lead.details.loanAmount > 500000 && (
              <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#FBC02D', color: 'white', borderRadius: '12px', padding: '1px 6px', fontSize: '12px', mr: 0 ,boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',}}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '12px', height: '12px', backgroundColor: 'white', borderRadius: '50%', mr: 0.5 }}>
                  <Typography sx={{ color: '#FBC02D', fontSize: '12px', fontWeight: 'bold' }}>$</Typography>
                </Box>
                Large Loan
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ ml: 0, mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', fontWeight: 'bold', }}>
        <Box>
          <Typography variant="body2">
            Loan: {formatCurrency(lead.details.loanAmount)}
          </Typography>
          <Typography variant="body2">
            Income: {formatCurrency(lead.details.monthlyIncome)}
          </Typography>
        </Box>
    {/* Purchase count bubble */}
    <Box 
          sx={{
            fontWeight: 'bold',
            backgroundColor: '#ADD8E6',
            color: '#333333',
            borderRadius: '12px',
            padding: '1px 6px',
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '24px',
            height: '20px',
            boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)', // Soft shadow for faded border effect

          }}
        >
          {lead.purchasedCount || 0}
        </Box>
        </Box>
      </CardContent>
    </Card>
  );
};


function ExpandedLeadCard({ lead, isMobile, handleBackToList, loading, handlePurchase, response, error }) {
    if (!lead) {
      return null; // Or some fallback UI
    }

    return (
      <Card 
        sx={{
          mb: 1, 
          boxShadow: 2, 
          backgroundColor: '#ffffff', 
          borderRadius: '10px', 
          padding: '0px', 
          display: 'flex', 
          flexDirection: 'column',
          height: '100%', 
          overflowY: 'auto', 
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <CardContent 
          sx={{ 
            overflowY: 'auto', 
            p: 2, 
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column',
            paddingBottom: 'calc(env(safe-area-inset-bottom, 64px) + 16px)'
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center', 
              flexDirection: 'column', 
              mb: 2, 
              position: 'relative', 
              width: '100%'
            }}
          >
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                width: '100%'
              }}
            >
              {isMobile && (
                <IconButton sx={{ pl: 0, pr: 0 }} onClick={handleBackToList}>
                  <ArrowBackIcon />
                </IconButton>
              )}
              <Typography 
                variant={isMobile ? "h6" : "h5"} 
                sx={{ 
                  fontSize: isMobile ? '1.5rem' : '1.75rem',
                  alignItems: 'center',
                  flexGrow: 1 ,
                  pl: isMobile ? 2: 0
                }}
              >
                {lead.name.split(' ')[0]}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%'
              }}>
                {renderTimeSinceInquiry(lead.date)}
              </Box>
            </Box>
          </Box>
  
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                Phone: {lead.phone}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                backgroundColor: '#66BB6A', 
                color: 'white', 
                borderRadius: '12px', 
                padding: '2px 8px', 
                fontSize: '12px', 
                mt: 0.5 
              }}>
                <CheckCircleIcon sx={{ fontSize: '16px', mr: '4px' }} />
                Verified
              </Box>
            </Box>
    
            <Typography variant="body2" sx={{ mb: 2 }}>
              Email: {lead.email}
            </Typography>
    
            <Typography variant="body1" sx={{ mb: 2 }}>
              {lead.purchasedCount === 1 
                ? '1 person has responded to ' 
                : `${lead.purchasedCount} people have responded to `}
              {lead.name.split(' ')[0]}
            </Typography>
    
  
          <Button
            variant="contained"
            sx={{
              mb: 2,
              backgroundColor: '#007bff',
              color: '#ffffff',
              padding: '8px 16px',
              width: isMobile ? '100%' : '350px',
              '&:hover': {
                backgroundColor: '#0056b3',
              },
              maxwidth: '450px'
            }}
            disabled={loading}
            onClick={(e) => {
              e.stopPropagation();
              handlePurchase(lead._id);
            }}
          >
            {loading ? (
              <CircularProgress size={20} sx={{ color: 'white' }} />
            ) : (
              `Contact ${lead.name.split(' ')[0]} for ${formatCurrency(lead.price)}`
            )}
          </Button>
  
        {renderAlert(response, 'success', isMobile)}
        {renderAlert(error, 'error', isMobile)}

          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Quick look
            </Typography>
    
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1, flexWrap: 'wrap' }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                backgroundColor: '#66BB6A', 
                color: 'white', 
                borderRadius: '12px', 
                padding: '1px 6px', 
                fontSize: '12px' 
              }}>
                <CheckCircleIcon sx={{ fontSize: '14px', mr: 0.5 }} />
                SMS Verified
              </Box>
    
              {lead.details.loanAmount > 500000 && (
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  backgroundColor: '#FBC02D', 
                  color: 'white', 
                  borderRadius: '12px', 
                  padding: '1px 6px', 
                  fontSize: '12px', 
                  mt: 0.5 
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    width: '14px', 
                    height: '14px', 
                    backgroundColor: 'white', 
                    borderRadius: '50%', 
                    mr: 0.5 
                  }}>
                    <Typography sx={{ color: '#FBC02D', fontSize: '12px', fontWeight: 'bold' }}>$</Typography>
                  </Box>
                  Large Loan
                </Box>
              )}
            </Box>
    
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Details
            </Typography>
    
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography paragraph sx={{ mb: 1 }}>
                  <strong>What is your monthly income?</strong>
                  <br />
                  {formatCurrency(lead.details.monthlyIncome)}
                </Typography>
                <Typography paragraph sx={{ mb: 1 }}>
                  <strong>What loan amount are you looking for?</strong>
                  <br />
                  {formatCurrency(lead.details.loanAmount)}
                </Typography>
                <Typography paragraph sx={{ mb: 1 }}>
                  <strong>What is your employment status?</strong>
                  <br />
                  {lead.details.employment}
                </Typography>
                <Typography paragraph sx={{ mb: 1 }}>
                  <strong>What is your credit rating?</strong>
                  <br />
                  {lead.details.credit}
                </Typography>
              </Grid>
            </Grid>
    
              </CardContent>
      </Card>
    );
  };


  export { SmallLeadCard, ExpandedLeadCard };
