// src/services/apiService.js
import axios from 'axios';

const apiService = (() => {
  const fetchUserProfile = async (authHeader) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/profile`,
      {
        headers: { Authorization: authHeader },
      }
    );
    const userProfile = response.data;
    console.log(response.data);
    // Update session storage if necessary, but prefer updating state
    return userProfile;
  };

  const fetchAvailableLeads = async (
    authHeader,
    search = '',
    loanAmountMinimum = 'None',
    state = 'All'
  ) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/leads`,
      {
        params: {
          fields: 'name,price,phone,email,details,purchasedCount,date',
          search,
          loanAmountMinimum,
          state,
          limit: 'none',
          obfuscate: true,
          purchased: 'user',
        },
        headers: { Authorization: authHeader },
      }
    );
    console.log(response);
    const availableLeads = response.data.leads;
    const availableLeadsCount = availableLeads.length;
    // Update session storage if necessary, but prefer updating state
    return { availableLeads, availableLeadsCount };
  };

  const fetchPurchasedLeads = async (authHeader) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/leads`,
      {
        params: { onlyPurchasedByUser: 'true', limit: 'none' },
        headers: { Authorization: authHeader },
      }
    );
  
    const purchasedLeads = response.data.leads;
    const settledCount = purchasedLeads.filter((lead) => lead.settled).length;
    const conversionRate = Number(
      Math.round(
        purchasedLeads.length ? (settledCount / purchasedLeads.length) * 100 : 0
      )
    );
  
    return { purchasedLeads, settledCount, conversionRate };
  };

  const fetchAdminUsers = async (authHeader) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/admin/users`,
      {
        headers: { Authorization: authHeader },
      }
    );
    const adminUsers = response.data;
    // Update session storage if necessary, but prefer updating state
    return adminUsers;
  };

  const updateUserRole = async (authHeader, userId, newRole) => {
    return await axios.put(
      `${process.env.REACT_APP_API_URL}/api/admin/users/${userId}`,
      { role: newRole },
      { headers: { Authorization: authHeader } }
    );
  };


  const handlePurchase = async (authHeader, leadId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/leads/${leadId}/purchase`,
        {},
        {
          headers: { Authorization: authHeader },
        }
      );

      console.log(response);

      // Fetch the updated leads after purchase
      const purchasedLeads = await fetchPurchasedLeads(authHeader);
      console.log('Purchased leads updated:', purchasedLeads);

      const { availableLeads, availableLeadsCount } = await fetchAvailableLeads(
        authHeader
      );
      console.log('Available leads updated:', availableLeads);

      return { availableLeads, availableLeadsCount };
    } catch (err) {
      console.error('There was an error purchasing the lead!', err);
      let errorMessage = 'An unexpected error occurred. Please try again later.';
      if (err.response && err.response.data && err.response.data.code) {
        switch (err.response.data.code) {
          case 'ALREADY_PURCHASED':
            errorMessage = 'You have already purchased this lead.';
            break;
          case 'INSUFFICIENT_CREDITS':
            errorMessage = 'You do not have enough credits to purchase this lead.';
            break;
          case 'LEAD_NOT_FOUND':
            errorMessage = 'The lead you are trying to purchase was not found.';
            break;
          default:
            errorMessage = 'An unexpected error occurred. Please try again later.';
        }
      }
      throw new Error(errorMessage);
    }
  };


  const updateLeadSettledStatus = async (email, checked, authHeader) => {
    try {
      // Make a POST request to update the settled status
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/leads/settled`, 
        { email, settled: checked },
        { headers: { Authorization: authHeader } }
      );
  
      // Log or return the response for further processing
      return response.data; // Return data for further use (if needed)
    } catch (error) {
      console.error('Error updating lead settled status:', error);
  
      // Optionally, you can throw the error to handle it at the calling level
      throw new Error('There was an issue updating the settled status');
    }
  };



    const createPaymentIntentAndConfirm = async (
      amount,
      user,
      stripe,
      cardElement, // Accept the card element
      authHeader
    ) => {
      try {
        // Step 1: Create a payment intent with the specified amount and userId
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/payments/create-payment-intent`,
          {
            amount: parseFloat(amount) * 100, // Convert to cents
            userId: user.id, // Include the user ID
          },
          {
            headers: {
              Authorization: authHeader,
            },
          }
        );
    
        // Step 2: Confirm the card payment using the clientSecret from the backend
        const result = await stripe.confirmCardPayment(data.clientSecret, {
          payment_method: {
            card: cardElement, // Use the cardElement passed from the component
            billing_details: {
              name: `${user?.firstName || ''} ${user?.lastName || ''}`,
              email: user?.email || '',
            },
          },
        });
    
        // Return the result for further processing in the specific logic
        return result;
      } catch (error) {
        throw new Error(
          error.response?.data?.message || error.message || 'Payment failed.'
        );
      }
    };


  const updateCredits = async (user, amountValue, authHeader) => {
    const updateResponse = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/payments/update-credits`,
      { credits: amountValue, userId: user.id },
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
    const newCredits = updateResponse.data.balance;
    return newCredits
  }

  const trackAction = async (actionType, details, authHeader, userId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/tracking/track`,
        {
          actionType,    // e.g., 'filterChange'
          details,       // e.g., { filter: 'search' }
          userId,        // The ID of the user performing the action
        },
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error tracking action:', error.message);
      return null;
    }
  };



  return {
    fetchUserProfile,
    fetchAvailableLeads,
    fetchPurchasedLeads,
    fetchAdminUsers,
    updateUserRole,
    handlePurchase,
    updateLeadSettledStatus,
    createPaymentIntentAndConfirm,
    updateCredits,
    trackAction
  };
})();

export default apiService;