import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  Select,
  FormControl,
  Drawer,
  Checkbox,
  Button,
  ListItemText,
} from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import AuthWrapper from './AuthWrapper';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../services/apiService';
import { paginateLeads, formatCurrency } from '../utils/Utils';
import { useLayout } from '../contexts/LayoutContext';

function MyLeads() {
  const { isMobile } = useLayout();

  const columns = [
    {
      id: 'purchasedDate',
      label: 'Purchase Date',
      format: (value) => new Date(value).toLocaleDateString(),
      selected: true,
    },
    { id: 'name', label: 'Name', minWidth: 150, selected: true },
    { id: 'email', label: 'Email', selected: true },
    {
      id: 'phone',
      label: 'Phone',
      format: (value) =>
        value && value.length === 10
          ? `${value.slice(0, 4)} ${value.slice(4, 7)} ${value.slice(7)}`
          : value,
      minWidth: 95,
      selected: true,
    },
    {
      id: 'date',
      label: 'Date Added',
      format: (value) => new Date(value).toLocaleDateString(),
      selected: false,
    },
    {
      id: 'details.monthlyIncome',
      label: 'Monthly Income',
      format: (value) => formatCurrency(value),
      selected: !isMobile,
    },
    {
      id: 'details.loanAmount',
      label: 'Loan Amount',
      format: (value) => formatCurrency(value),
      selected: true,
    },
    {
      id: 'details.houseValue',
      label: 'House Value',
      format: (value) => formatCurrency(value),
      selected: !isMobile,
    },
    { id: 'details.credit', label: 'Credit', selected: !isMobile },
    { id: 'details.employment', label: 'Employment', selected: !isMobile },
    { id: 'details.currentRate', label: 'Current Rate', selected: false },
    { id: 'details.loanType', label: 'Loan Type', selected: false },
    { id: 'details.address', label: 'Address', selected: false },
    { id: 'details.state', label: 'State', selected: !isMobile },
    { id: 'details.postcode', label: 'Postcode', selected: false },
    { id: 'settled', label: 'Converted?', isCheckbox: true, selected: true },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage, setLeadsPerPage] = useState(25);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.filter((column) => column.selected).map((column) => column.id)
  );
  const [sortConfig, setSortConfig] = useState({ key: 'purchasedDate', direction: 'asc' });

  const { user, purchasedLeads, setPurchasedLeads, setSettledCount, setConversionRate, getAuthHeader } = useAuth();

  const paginatedLeads = paginateLeads(purchasedLeads, currentPage, leadsPerPage);

  const handleCheckboxChange = async (leadId, checked) => {
    try {
      const lead = purchasedLeads.find((lead) => lead._id === leadId);
      if (!lead) {
        console.error('Lead not found');
        return;
      }

      const { email } = lead;
      await apiService.updateLeadSettledStatus(email, checked, getAuthHeader());

      const updatedLeads = purchasedLeads.map((lead) =>
        lead._id === leadId ? { ...lead, settled: checked } : lead
      );
      setPurchasedLeads(updatedLeads);

      const updatedSettledCount = updatedLeads.filter((lead) => lead.settled).length;
      setSettledCount(updatedSettledCount);
      const conversionRate = Number(
        Math.round(
          purchasedLeads.length ? (updatedSettledCount / updatedLeads.length) * 100 : 0
        )
      );
      setConversionRate(conversionRate);
    } catch (error) {
      console.error('Error updating lead:', error);
    }
  };

  const exportToCSV = () => {
    const csvContent = [
      columns
        .filter((column) => selectedColumns.includes(column.id))
        .map((column) => column.label)
        .join(','), // Headers
      ...purchasedLeads.map((lead) =>
        selectedColumns
          .map((columnId) => {
            const column = columns.find((col) => col.id === columnId);
            const value = columnId.includes('.')
              ? columnId.split('.').reduce((o, key) => o[key], lead)
              : lead[columnId];
            return column.format ? column.format(value) : value;
          })
          .join(',')
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'leads.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Sorting logic
  const sortLeads = (leads) => {
    return [...leads].sort((a, b) => {
      if (sortConfig.key === 'name') {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (nameA > nameB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      }
      if (sortConfig.key === 'purchasedDate') {
        const dateA = new Date(a.purchasedDate);
        const dateB = new Date(b.purchasedDate);
        return sortConfig.direction === 'asc'
          ? dateA - dateB
          : dateB - dateA;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const totalPages = Math.ceil(purchasedLeads.length / leadsPerPage);
  const sortedLeads = sortLeads(paginatedLeads);

  return (
    <AuthWrapper user={user}>
      {user && (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '8px' }}>
            <Typography variant="h4" gutterBottom margin="0px">My Leads</Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="contained" onClick={() => setDrawerOpen(true)}>Show Columns</Button>
              {!isMobile && <Button variant="contained" onClick={exportToCSV}>Export Leads</Button>}
            </Box>
          </Box>

          {/* Drawer for showing/hiding columns */}
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            sx={{ '& .MuiDrawer-paper': { width: 240 } }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant="h6">Select Columns</Typography>
              {columns.map((column) => (
                <Box key={column.id} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={selectedColumns.includes(column.id)}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setSelectedColumns((prev) =>
                        checked ? [...prev, column.id] : prev.filter((id) => id !== column.id)
                      );
                    }}
                  />
                  <ListItemText primary={column.label} />
                </Box>
              ))}
            </Box>
          </Drawer>

          <Paper sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <TableContainer
              component={Box}
              sx={{
                maxHeight: isMobile ? 'calc(100vh - 170px)' : 'calc(100vh - 114px)',
                overflowX: 'auto',
                borderRadius: '10px',
                pb: 0,
                mb: 0,
              }}
            >
              <Table stickyHeader aria-label="sticky table" sx={{ minWidth: { xs: 600, sm: 800, md: 1000 } }}>
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((column) => selectedColumns.includes(column.id))
                      .map((column) => (
                        <TableCell
                        key={column.id}
                        sx={{ 
                          p: 1, 
                          textAlign: 'center', 
                          backgroundColor: 'white', 
                          top: 0, 
                          zIndex: 2
                        }}
                        onClick={() => (column.id === 'name' || column.id === 'purchasedDate') && handleSort(column.id)}
                      >
                        <Box
                          sx={{
                            display: 'inline-flex',  // Aligns label and icons in a row
                            alignItems: 'center',  // Vertically centers the label and icons
                            justifyContent: 'center' // Centers content in the header cell
                          }}
                        >
                          {column.label}
                          {column.id === 'name' || column.id === 'purchasedDate' ? (
                            sortConfig.key === column.id ? (
                              sortConfig.direction === 'asc' ? (
                                <ArrowDropUp sx={{ ml: 0.5 }} />
                              ) : (
                                <ArrowDropDown sx={{ ml: 0.5 }} />
                              )
                            ) : (
                              <>
                                <ArrowDropUp sx={{ opacity: 0.3, ml: 0.5 }} />
                                <ArrowDropDown sx={{ opacity: 0.3, ml: 0.5 }} />
                              </>
                            )
                          ) : null}
                        </Box>
                      </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedLeads.map((lead) => (
                    <TableRow key={lead._id}>
                      {columns.map((column) =>
                        selectedColumns.includes(column.id) ? (
                          <TableCell
                            key={column.id}
                            sx={{
                              p: 1,
                              minWidth: column.minWidth || 'auto',
                              textAlign: 'center',
                              fontSize: { xs: '10px', sm: '12px' },
                            }}
                          >
                            {column.isCheckbox ? (
                              <Checkbox
                                checked={lead.settled}
                                onChange={(e) => handleCheckboxChange(lead._id, e.target.checked)}
                              />
                            ) : column.format ? (
                              column.format(
                                column.id.includes('.')
                                  ? column.id.split('.').reduce((o, key) => o[key], lead)
                                  : lead[column.id]
                              )
                            ) : column.id.includes('.') ? (
                              column.id.split('.').reduce((o, key) => o[key], lead)
                            ) : (
                              lead[column.id]
                            )}
                          </TableCell>
                        ) : null
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_, value) => setCurrentPage(value)}
              />
              <FormControl sx={{ height: 'auto' }}>
                <Select
                  labelId="leads-per-page-label"
                  value={leadsPerPage}
                  onChange={(e) => setLeadsPerPage(e.target.value)}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </Box>
      )}
    </AuthWrapper>
  );
}

export default MyLeads;