import React, { useState } from 'react';
import { Route, Routes, Navigate, BrowserRouter as Router } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { AuthProvider } from './contexts/AuthContext';
import { LayoutProvider } from './contexts/LayoutContext';
import LeadsList from './components/leadslist/LeadsList';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import MyLeads from './components/MyLeads';
import Webhook from './components/Webhook';
import SignUp from './components/SignUp';
import SignUpFee from './components/SignUpFee';
import PasswordResetRequest from './components/PasswordResetRequest';
import ResetPassword from './components/ResetPassword';
import AdminPanel from './components/AdminPanel';
import VerifyEmail from './components/VerifyEmail';
import NavBar from './components/NavBar';
import BasicNavBar from './components/BasicNavBar';
import InitialDataLoader from './components/InitialDataLoader';

function App() {
  const [user, setUser] = useState(null);
  
  return (
    <Router>
      <AuthProvider user={user} setUser={setUser}>
        <LayoutProvider>
          <CssBaseline />
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#F5F7FA' }}>
            
            {/* Only show authenticated routes with InitialDataLoader */}
            {user ? (
              <>
                <Routes>
                  {/* Check if the current route is /signup-fee and render BasicNavBar, otherwise NavBar */}
                  <Route path="/signup-fee" element={<BasicNavBar />} />
                  <Route path="*" element={<NavBar />} />
                </Routes>
                <InitialDataLoader>
                  <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Routes>
                      <Route path="/" element={<Navigate to="/dashboard" />} />
                      <Route path="/leads" element={<LeadsList />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/my-leads" element={<MyLeads />} />
                      <Route path="/webhook" element={<Webhook />} />
                      <Route path="/admin" element={<AdminPanel />} />
                      <Route path="/signup-fee" element={<SignUpFee />} />
                      <Route path="*" element={<Navigate to="/dashboard" />} /> {/* Redirect undefined routes */}
                    </Routes>
                  </Box>
                </InitialDataLoader>
              </>
            ) : (
              // Render non-authenticated routes with BasicNavBar
              <>
                <BasicNavBar />
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                  <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/reset-password-request" element={<PasswordResetRequest />} />
                    <Route path="/reset-password/:token" element={<ResetPassword />} />
                    <Route path="/verify-email/:token" element={<VerifyEmail />} />
                    <Route path="*" element={<Navigate to="/login" />} /> {/* Redirect undefined routes */}
                  </Routes>
                </Box>
              </>
            )}
          </Box>
        </LayoutProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;