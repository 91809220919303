import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import LeadsChart from './LeadsChart';
import AuthWrapper from './AuthWrapper';
import { useAuth } from '../contexts/AuthContext';
import { useLayout } from '../contexts/LayoutContext';

function Dashboard() {
  const { user, purchasedLeads, settledCount, conversionRate } = useAuth();
  const { isMobile } = useLayout()

  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    if (purchasedLeads.length > 0) {
      const firstPurchaseDate = new Date(
        Math.min(
          ...purchasedLeads.map((lead) => new Date(lead.purchasedDate))
        )
      );
      firstPurchaseDate.setHours(firstPurchaseDate.getHours() + 10); // Convert UTC to AEST
      setStartDate(firstPurchaseDate.toISOString().split('T')[0]);
    }
  }, [purchasedLeads]);

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return 'Good morning';
    if (currentHour < 18) return 'Good afternoon';
    return 'Good evening';
  };

  const aggregatedData = purchasedLeads.reduce((acc, lead) => {
    const date = new Date(lead.purchasedDate);
    date.setHours(date.getHours() + 10); // Convert UTC to AEST
    const isoDate = date.toISOString().split('T')[0];

    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-AU', options);

    if (!acc[isoDate]) {
      acc[isoDate] = { date: isoDate, formattedDate, totalLeads: 0, totalSpent: 0 };
    }

    acc[isoDate].totalLeads += 1;
    acc[isoDate].totalSpent += lead.price;

    return acc;
  }, {});

  const chartData = Object.values(aggregatedData).sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  const StatCard = ({ value, label, isMobile, color = '#000000' }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        justifyContent: isMobile ? 'space-between' : 'center',
        alignItems: 'center',
        pr:1,
        pl:1
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          width: 80,
          height: 80,
          background: 'linear-gradient(145deg, #e0e0e0, #f9f9f9)',
          boxShadow:
            '3px 3px 10px rgba(0, 0, 0, 0.1), -3px -3px 10px rgba(255, 255, 255, 0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 'bold', color }}>
          {value}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        sx={{
          color: '#555',
          fontWeight: 'bold',
          pt: isMobile ? 0 : 1,
          pl: isMobile ? 1 : 0,
        }}
      >
        {label}
      </Typography>
    </Box>
  );

  const stats = [
    { label: 'Purchased', value: purchasedLeads.length, color: '#000000' },
    { label: 'Settled', value: settledCount, color: '#222222' },
    { label: 'Conversion', value: `${conversionRate}%`, color: '#000000' },
  ];

  return (
    <AuthWrapper user={user}>
      {user && (
        <Box sx={{ p: 0 }}>
          <Typography variant="h4" gutterBottom padding={'8px'} margin={'0px'}>
            {getGreeting()}, {user.firstName}
          </Typography>
          <Box
            sx={{
              p: 1,
              overflowY: 'auto',
              height: `calc(100vh - 120px)`,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ p: 1, mb: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: isMobile ? 'column' : 'row',
                      justifyContent: 'space-evenly',
                      gap: isMobile ? 2 : 4,
                    }}
                  >
                    {stats.map(({ label, value, color }) => (
                      <StatCard key={label} value={value} label={label} isMobile={isMobile} color={color} />
                    ))}
                  </Box>
                </Paper>
  
                <LeadsChart data={chartData} startDate={startDate} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </AuthWrapper>
  );
}

export default Dashboard;