// src/components/AddCredits.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { styled } from '@mui/system';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Modal,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AuthWrapper from './AuthWrapper';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../services/apiService';
import { formatCurrency } from '../utils/Utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardElementContainer = styled('div')({
  padding: '15px 10px',
  borderRadius: '4px',
  border: '1px solid #ced4da',
  marginTop: '16px',
  backgroundColor: '#f8f9fa',
});

const StyledTextField = styled(TextField)({
  marginBottom: '16px',
  '& label': {
    color: '#495057',
  },
  '& .MuiInputBase-root': {
    color: '#495057',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ced4da',
    },
    '&:hover fieldset': {
      borderColor: '#868e96',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#495057',
    },
  },
});

const PaymentForm = ({ user, setUser, handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const { getAuthHeader } = useAuth();
  const authHeader = getAuthHeader();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const amountValue = parseFloat(amount);

    if (isNaN(amountValue) || amountValue <= 0) {
      setError('Please enter a valid amount greater than zero.');
      return;
    }

    setLoading(true);
    setError(null);
    setResponse(null);

    try {
      // Get the card element reference
      const cardElement = elements.getElement(CardElement);
  
      // Pass the cardElement to the service
      const result = await apiService.createPaymentIntentAndConfirm(
        amountValue,
        user,
        stripe,
        cardElement,
        authHeader
      );

      if (result.error) {
        setError(result.error.message);
      } else if (result.paymentIntent.status === 'succeeded') {
        setAmount('');
        console.log(result.paymentIntent.status)
        const newCredits = await apiService.updateCredits(user, amountValue, authHeader)

       

        setResponse(`\nBalance: ${formatCurrency(newCredits)}`);
        // Optionally close the modal immediately or after a short delay
        setTimeout(() => {
 // After updating the credits
 setUser((prevUser) => ({
  ...prevUser,
  credits: newCredits, // Update the credits property
}));


          handleClose();
        }, 1000);
      } else {
        setError('Payment failed. Please try again.');
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          error.message ||
          'An unexpected error occurred.'
      );
    } finally {
      setLoading(false);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#495057',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        '::placeholder': {
          color: '#868e96',
        },
      },
      invalid: {
        color: '#fa755a',
      },
    },
    hidePostalCode: true,
  };

  return (
    <Box sx={{ p: 2 }}>
      <Paper sx={{ p: 2, mb: 2, position: 'relative' }}>
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" gutterBottom>
          Add Credits
        </Typography>
        <form onSubmit={handleSubmit} autoComplete="off">
          <StyledTextField
            label="Amount (AUD)"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            margin="normal"
            autoComplete="off"
          />
          <CardElementContainer>
            <CardElement options={cardElementOptions} />
          </CardElementContainer>
          <Box sx={{ position: 'relative', mt: 2 }}>
            <Button
              variant="contained"
              type="submit"
              sx={{ width: '100%' }}
              disabled={!stripe || loading}
            >
              {loading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Pay Now'
              )}
            </Button>
          </Box>
          {response && (
            <Alert severity="success" sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ whiteSpace: 'pre-line' }}>
                Payment Successful!
              </Typography>
              {response}
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <Typography variant="h6" color="error">
                Error
              </Typography>
              <pre>{error}</pre>
            </Alert>
          )}
        </form>
      </Paper>
    </Box>
  );
};

const AddCredits = ({ user, setUser, isOpen, setIsOpen }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          width: { xs: '90%', sm: '80%', md: '50%' },
          maxWidth: 600,
          p: 0,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <AuthWrapper user={user}>
          <Elements stripe={stripePromise}>
            <PaymentForm user={user} setUser={setUser} handleClose={handleClose} />
          </Elements>
        </AuthWrapper>
      </Box>
    </Modal>
  );
};

export default AddCredits;