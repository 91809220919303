// src/components/SignUpFee.js
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { styled } from '@mui/system'; 
import { Box, Button, Typography, Paper, CircularProgress, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../services/apiService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardElementContainer = styled('div')({
  padding: '15px 10px',
  borderRadius: '4px',
  border: '1px solid #ced4da',
  marginTop: '16px',
  backgroundColor: '#f8f9fa',
});

const SignUpFeeForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, getAuthHeader, setStatus } = useAuth(); // Destructure setStatus from context
  const authHeader = getAuthHeader();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
// Get the card element reference
const cardElement = elements.getElement(CardElement);


      // Create and confirm the payment intent
      const result = await apiService.createPaymentIntentAndConfirm(99, user, stripe, cardElement, authHeader);

      if (result.error) {
        setError(result.error.message);
      } else if (result.paymentIntent.status === 'succeeded') {
        setSuccess(true);
        // Notify the backend to update the user's status
        await axios.post(`${process.env.REACT_APP_API_URL}/api/signup/complete`, { userId: user.id });

       

        setTimeout(() => {
            // Update the status in the AuthContext to 'active'
        console.log('setting status to active')
        setStatus('active');
          console.log('[Client] Navigating to dashboard');
          navigate('/leads'); // Navigate to the dashboard or another authenticated route
        }, 2000);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#495057',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        '::placeholder': {
          color: '#868e96',
        },
      },
      invalid: {
        color: '#fa755a',
      },
    },
    hidePostalCode: true,
    autoComplete: 'off',
  };

  return (
    <Box sx={{ maxWidth: 450, mx: 'auto', p: 2 }}>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Complete Your Sign-Up
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please enter your payment details below to pay the $99 sign-up fee and complete your registration.
        </Typography>
        <form onSubmit={handleSubmit} autoComplete="off">
          <CardElementContainer>
            <CardElement options={cardElementOptions} />
          </CardElementContainer>
          <Box sx={{ position: 'relative', mt: 2 }}>
            <Button
              variant="contained"
              type="submit"
              sx={{ width: '100%' }}
              disabled={!stripe || loading}
            >
              {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Pay $99'}
            </Button>
          </Box>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              <Typography variant="h6" color="error">
                Error
              </Typography>
              <pre>{error}</pre>
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mt: 2 }}>
              <Typography variant="h6">Payment Successful!</Typography>
              Redirecting...
            </Alert>
          )}
        </form>
      </Paper>
    </Box>
  );
};

const SignUpFee = () => (
  <Elements stripe={stripePromise}>
    <SignUpFeeForm />
  </Elements>
);

export default SignUpFee;